import * as React from 'react'
import { Container } from '../components/container'
import { useTheme } from '@conte-ltd/components-theme'
import { Theme } from '../styles/theme'
import { useMediaQuery } from '@conte-ltd/components-utils'
import { Separator } from '@conte-ltd/components-separator'
import { Layout } from '../components/layout'
import { Section } from '../components/section'

const PrivacyPolicyPage = () => {
  const {
    styles: {
      margin,
      padding,
      display,
      flexDirection,
      alignItems,
      gap,
      alignSelf,
      fontFamily,
      fontSize,
      fontWeight,
      letterSpacing,
      lineHeight,
      textAlign,
      color,
    },
    breakpoints: bp,
  } = useTheme<Theme>()
  const isMobile = useMediaQuery(bp.lg)
  const isDesktop = !isMobile

  const sitePolicy = [
    {
      heading: '個人情報の収集と管理について',
      content: (
        <>
          当社は、個人情報の収集は適法かつ公正な方法で行います。
          <br />
          個人情報の収集範囲は、その利用目的のために必要な範囲とし、その保管・管理には適切な措置を講じます。
        </>
      ),
    },
    {
      heading: '個人情報の利用目的',
      content: (
        <>
          収集した個人情報は、以下の目的のために利用させていただきます。その目的を越えて利用することはありません。
          <br />
          ○当社の製品・サービスおよび関連情報のご案内、ご提供
          <br />
          ○お客さまからのお問合せへの対応
        </>
      ),
    },
    {
      heading: '個人情報の第三者提供',
      content: (
        <>
          当社は収集した個人情報は、以下の場合を除いて第三者に開示いたしません。
          <br />
          ○あらかじめご本人の同意がある場合
          <br />
          ○裁判所または法令等により要求または許容されている場合
          <br />
          ○個人識別ができない状態で開示する場合
        </>
      ),
    },
    {
      heading: '個人情報に関するお問い合わせ先',
      content: (
        <>
          本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
          <br />
          <span
            css={[
              display.inlineBlock,
              isMobile ? margin.top['3xs'] : margin.top['5xs'],
            ]}
          >
            株式会社CONTE
            <br />
            住所：〒443-0034　愛知県蒲郡市港町13-33 {isMobile ? <br /> : '／ '}
            E-mail：info@conte.ltd
          </span>
        </>
      ),
    },
  ]

  return (
    <Layout>
      <Section id={'privacy-policy'} className={'light-mode'}>
        <Container
          css={[
            isDesktop && padding.x.xl,
            isMobile ? padding.y['4xl'] : padding.y['5xl'],
            display.flex,
            flexDirection.column,
            alignItems.center,
            margin.x.auto,
          ]}
        >
          <h1
            css={[isMobile ? fontSize.xl : fontSize['4xl'], textAlign.center]}
          >
            PRIVACY POLICY
          </h1>

          <h2
            css={[
              isMobile ? fontSize.sm : fontSize.md,
              fontWeight.medium,
              letterSpacing.tighter,
              textAlign.center,
              isMobile ? margin.top['2xs'] : margin.top.sm,
            ]}
          >
            プライバシーポリシー
          </h2>

          <p css={[margin.top.lg, isDesktop && textAlign.center]}>
            株式会社CONTE（以下、当社といいます）は、お客さまやお取引先の個人情報の重要性を認識し、
            {isDesktop && <br />}
            これらを保護しかつ適正に取扱うことは、重要な社会的責務であると考えております。
            <br />
            また、関係する法令等を遵守し、以下の取り組みを行うとともに、その継続的な改善に努めてまいります。
          </p>

          <section
            css={[
              display.flex,
              flexDirection.column,
              gap.all.sm,
              margin.top['4xl'],
            ]}
          >
            <h2
              css={[
                fontFamily.jp,
                fontSize['2lg'],
                fontWeight.semibold,
                lineHeight.relaxed,
                textAlign.center,
                margin.y.offset.negative.xs,
              ]}
            >
              個人情報の取扱いについて
            </h2>

            <Separator size={'6rem'} css={alignSelf.center} />

            <div
              css={[
                display.flex,
                flexDirection.column,
                gap.all.md,
                margin.top['3xs'],
              ]}
            >
              {sitePolicy.map(({ heading, content }, index) => (
                <section
                  key={index}
                  css={[display.flex, flexDirection.column, gap.all.xs]}
                >
                  <h3
                    css={[
                      fontSize.lg,
                      lineHeight.relaxed,
                      margin.y.offset.negative.xs,
                    ]}
                  >
                    <span css={color.secondaryLight}>●</span>
                    {heading}
                  </h3>

                  <p>{content}</p>
                </section>
              ))}
            </div>
          </section>
        </Container>
      </Section>
    </Layout>
  )
}

export default PrivacyPolicyPage
